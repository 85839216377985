import utils from '$lib/utils'

export function handleError({ error, event }) {
  if (error instanceof utils.AuthError) {
    // Try reloading the page so that the auth hook can try refreshing the
    // token. If that doesn't work the server equivalent of this hook will
    // redirect to signout.
    window.location = event.url.href
    return { message: error.message }
  }

  console.error(error)
  return { message: 'Sorry, something has gone wrong.' }
}
